.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener {
    width: 100vw;
    height: auto;
}

.textBox{
    margin: 3vw 0 0 0;
    text-align: center;
    font-size: 2.5vw;

    div:nth-of-type(1) {
        font-weight: 200;
    }

    div:nth-of-type(2) {
        margin-top: 0.5vw;
        font-weight: 800;
        color: #015573;
    }
}

.page1Image {
    margin: 4vw 0 4vw 0;
    width: 80%;

    @media (max-width: 900px) {
        width: 90%;
    }
}

.videoContainer {
    margin-top: 0 0 3vw 0;
    width: 80%;  /* 컨테이너의 너비를 100%로 설정 */
    max-width: 1000px; /* 최대 너비 설정 (원하는 최대 크기로 조정 가능) */
    position: relative;
    padding-bottom: 40%; /* 비율 기반 크기 (16:9 비율) */
    height: 0;
    overflow: hidden; /* 불필요한 스크롤을 방지 */
}

/* 모바일 화면에서는 가로를 90%로 설정 */
@media (max-width: 900px) {
    .videoContainer {
        margin-top: 5vw;
        width: 80%;  /* 모바일에서 가로를 90%로 설정 */
        max-width: 100%; /* 최대 너비는 100%로 설정 */
        padding-bottom: 56%; /* 모바일에서 16:9 비율 유지 */
    }
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* 테두리 제거 */
}