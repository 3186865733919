.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener{
    width: 100vw;
    height: auto;
}

.image {
    margin-top: 4vw;
    width: 80%;
    height: auto;

    @media (max-width: 900px) {
        width: 90%;
    }
}

.image2 {
    width: 80%;
    height: auto;

    @media (max-width: 900px) {
        width: 90%; 
    }
}

.textBox{
    margin: 3vw 0 0 0;
    text-align: center;
    font-size: 2.5vw;

    div:nth-of-type(1) {
        font-weight: 200;
    }

    div:nth-of-type(2) {
        margin-top: 0.5vw;
        font-weight: 800;
        color: #015573;
    }
}

.commonBox {
    margin: 2vw 0;
    width: 80%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 90%;
    }

    .notice {
        padding: 0 1vw;
        line-height: 1.1vw;
        font-size: 1vw;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0  3vw;
            font-size: 2.5vw;
            line-height: 3vw;
            text-indent: -2vw;
        }
    }

    .notice:nth-of-type(1) {
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(6) {
        padding-bottom: 1vw;
    }
}